<template>
    <main>
		<HeaderTab 
            :title="$t('global.notes')"
        />

		<div id="content">
			<div class="container-fluid">
                <div class="row">
					<div class="col-12">
						<NotesList />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
	export default {
		name: "noteListe",       
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
			NotesList: () => import('GroomyRoot/components/Notes/List')
		}
	}
</script>
